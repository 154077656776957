<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closePreviewModal" />
    <div class="modal-content">
      <photo v-if="isPhoto" :photo="photo" :width="300" :height="300" />

      <img v-else-if="isBannerPhoto" :src="preview" />
    </div>
    <button
      type="button"
      class="modal-close is-large"
      aria-label="close"
      @click="closePreviewModal"
    />
  </div>
</template>

<script>
import Photo from '@/views/components/Photo';

export default {
  components: {
    Photo,
  },

  props: {
    photo: {
      type: Object,
      required: false,
      default: null,
    },
    preview: {
      type: String,
      required: false,
      default: null,
    },
    isOpen: Boolean,
  },

  methods: {
    closePreviewModal() {
      this.$emit('closePreviewModal');
    },
    isBannerPhoto() {
      return !!this.preview;
    },
    isPhoto() {
      return !!this.photo;
    },
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
.modal-content {
  text-align: center;
}
</style>
