<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Φωτογραφία
        </div>
      </div>
      <div v-if="!hasPhoto" class="card-header-icon">
        <button type="button" class="button" @click="openUploadModal()">
          <span class="icon is-small"><i class="fa fa-plus"/></span
          ><span>Προσθήκη</span>
        </button>
      </div>
    </div>
    <div class="card-content">
      <LoadingContainer
        :is-loading="isLoading"
        :is-opaque="true"
        :is-vertically-aligned="true"
      >
        <div v-if="hasPhoto" class="photo">
          <image-card
            :photo="newPhoto"
            @on-preview="handlePreview"
            @on-edit="handleEdit"
            @on-delete="handleDelete"
          />
        </div>

        <div v-else>
          <h5 class="title is-5 is-marginless has-text-centered">
            Δε βρέθηκε Φωτογραφία
          </h5>
        </div>

        <image-upload-modal
          :is-open="isOpenUploadModal"
          :is-saving="isSaving"
          :id="`barcode-${model.id}`"
          :photos="photos"
          @closeUploadModal="handleCloseUpload"
          @upload="handleUpload"
        />

        <image-preview-modal
          :photo="newPhoto"
          :is-open="isOpenPreviewModal"
          @closePreviewModal="handleClosePreview"
        />

        <image-edit-modal
          :photo="newPhoto"
          :is-open="isOpenEditModal"
          :is-saving="isSaving"
          @on-edit="handleEditModal"
          @on-close="handleCloseEdit"
        />

        <confirmation
          :is-open="isOpenDeleteModal"
          :is-saving="isSaving"
          @closeModal="handleCloseDelete"
        >
          <h5 class="title is-5">
            Είστε βέβαιος για τη διαγραφή αυτής της φωτογραφίας;
          </h5>
        </confirmation>
      </LoadingContainer>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, pick } from 'lodash';
import { mapActions } from 'vuex';
import LoadingContainer from '@/views/components/LoadingContainer';
import ImageUploadModal from '@/views/components/ImageUploadModal';
import ImagePreviewModal from '@/views/components/ImagePreviewModal';
import Confirmation from '@/views/components/Confirmation';
import Notification from '@/views/components/Notification';
import ImageEditModal from './components/ImageEditModal';
import ImageCard from './components/ImageCard';

export default {
  components: {
    LoadingContainer,
    ImageUploadModal,
    ImagePreviewModal,
    Confirmation,
    Notification,
    ImageEditModal,
    ImageCard,
  },

  props: {
    model: Object,
    photos: Array,
    photo: Object,
    isLoading: Boolean,
  },

  data: () => ({
    newPhoto: {},
    isSaving: false,
    isOpenUploadModal: false,
    isOpenPreviewModal: false,
    isOpenEditModal: false,
    isOpenDeleteModal: false,
  }),

  computed: {
    hasPhoto() {
      return !isEmpty(this.newPhoto);
    },
  },

  watch: {
    photo: {
      handler(newVal) {
        this.newPhoto = cloneDeep(newVal);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      addPhoto: 'photos/add/addPhoto',
      deletePhoto: 'photos/destroy/deletePhoto',
      updatePhoto: 'photos/edit/updatePhoto',
    }),

    async handleUpload(file) {
      try {
        this.isSaving = true;
        const modelData = pick(this.model, ['id', 'slug']);

        await this.addPhoto({
          model: {
            ...modelData,
            is_avatar: true,
            photoable_type: 'App\\Barcode',
          },
          file,
        });

        await this.$emit('on-update');

        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η φωτογραφία αποθηκεύτηκε',
        });

        this.isOpenUploadModal = false;
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    openUploadModal() {
      this.isOpenUploadModal = true;
    },

    handleCloseUpload() {
      this.isOpenUploadModal = false;
    },

    handleClosePreview(isOpen) {
      this.isOpenPreviewModal = isOpen;
    },

    handleCloseEdit() {
      this.isOpenEditModal = false;
    },

    async handleCloseDelete(response) {
      try {
        if (response) {
          this.isSaving = true;
          await this.deletePhoto({
            id: this.newPhoto.id,
          });

          await this.$emit('on-update');
          this.isSaving = false;

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η φωτογραφία διαγράφτηκε',
          });
        }

        this.isOpenDeleteModal = false;
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async handleEditModal(photo) {
      try {
        this.isSaving = true;
        await this.updatePhoto({
          id: this.newPhoto.id,
          photo: {
            ...photo,
            photoable_id: this.model.id,
            photoable_type: 'App\\Barcode',
          },
        });

        await this.$emit('on-update');

        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η φωτογραφία αποθηκεύτηκε',
        });

        this.handleCloseEdit();
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    handlePreview() {
      this.isOpenPreviewModal = true;
    },

    handleEdit() {
      this.isOpenEditModal = true;
    },

    handleDelete() {
      this.isOpenDeleteModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  margin: 0 auto;
  width: 400px;

  &-content {
    .columns {
      margin-bottom: 0;
    }
  }
}

.photo {
  height: 150px;
}
</style>
