<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Δοκιμές Xρεώσεων Αποστολής
        </div>
      </div>
    </div>
    <div class="card-content">
      <table class="table is-bordered is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Αξία Παραγγελίας</th>
            <th>Βάρος Παραγγελίας</th>
            <th>Χρέωση</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="has-text-centered">
              <div class="field has-addons">
                <div class="control">
                  <input
                    v-model="sum"
                    type="number"
                    min="0"
                    step="0.01"
                    class="input has-text-centered"
                    name="sum"
                  />
                </div>
                <div class="control">
                  <a class="button is-static">
                    &euro;
                  </a>
                </div>
              </div>
            </td>
            <td class="has-text-centered">
              <div class="field has-addons">
                <div class="control">
                  <input
                    v-model="weight"
                    type="number"
                    min="0"
                    step="0.01"
                    class="input has-text-centered"
                    name="weight"
                  />
                </div>
                <div class="control">
                  <a class="button is-static">
                    Kg
                  </a>
                </div>
              </div>
            </td>
            <td class="has-text-centered">
              <h2 class="title is-2">{{ total }} &euro;</h2>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { calculateSendCharges } from '@/utils/calculations';

export default {
  props: {
    charges: Object,
  },
  data: () => ({
    sum: 0,
    weight: 0,
  }),
  computed: {
    total() {
      return calculateSendCharges(this.sum, this.weight, this.charges);
    },
  },
};
</script>

<style lang="scss" scoped>
.field.has-addons {
  justify-content: center;
}
</style>
