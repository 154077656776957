<template>
  <LoadingContainer :is-loading="isLoadingAuthor">
    <form class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Authors <span class="tag">{{ model.name }}</span>
            </div>
          </div>
          <div class="card-header-icon">
            <router-link :to="{ name: 'content.authors.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Oνοματεπώνυμο *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="general.name"
                    class="input"
                    type="text"
                    name="name"
                  />
                  <p v-show="errors.has('name')" class="help is-danger">
                    Εισάγετε ονοματεπώνυμο
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Ειδικότητα *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="general.specialty"
                    class="input"
                    type="text"
                    name="specialty"
                  />
                  <p v-show="errors.has('specialty')" class="help is-danger">
                    Εισάγετε ειδικότητα
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-full">
              <div class="field">
                <label class="label">Βιογραφικό</label>
                <div class="control">
                  <textarea
                    v-model.trim="general.bio"
                    name="bio"
                    class="textarea"
                  />
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Facebook</label>
                <div class="control">
                  <input
                    v-validate="'url'"
                    v-model.trim="general.facebook"
                    class="input"
                    type="text"
                    name="facebook"
                  />
                  <p v-show="errors.has('facebook')" class="help is-danger">
                    Εισάγετε έγκυρο url
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Twitter</label>
                <div class="control">
                  <input
                    v-validate="'url'"
                    v-model.trim="general.twitter"
                    class="input"
                    type="text"
                    name="twitter"
                  />
                  <p v-show="errors.has('twitter')" class="help is-danger">
                    Εισάγετε έγκυρο url
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Instagram</label>
                <div class="control">
                  <input
                    v-validate="'url'"
                    v-model.trim="general.instagram"
                    class="input"
                    type="text"
                    name="instagram"
                  />
                  <p v-show="errors.has('instagram')" class="help is-danger">
                    Εισάγετε έγκυρο url
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Linkedin</label>
                <div class="control">
                  <input
                    v-validate="'url'"
                    v-model.trim="general.linkedin"
                    class="input"
                    type="text"
                    name="linkedin"
                  />
                  <p v-show="errors.has('linkedin')" class="help is-danger">
                    Εισάγετε έγκυρο url
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Webpage</label>
                <div class="control">
                  <input
                    v-validate="'url'"
                    v-model.trim="general.webpage"
                    class="input"
                    type="text"
                    name="webpage"
                  />
                  <p v-show="errors.has('webpage')" class="help is-danger">
                    Εισάγετε έγκυρο url
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <edit-photos
        :model="model"
        :photos="photos"
        :is-loading="isLoadingPhotos"
        @updatePhotos="updatePhotos"
      />

      <ToggleableCard :is-open="true" title="Άρθρα">
        <div class="card-content">
          <table
            v-if="posts.length"
            class="table is-bordered is-striped is-marginless is-fullwidth"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Τίτλος</th>
                <th class="hidden-print">Ενέργειες</th>
              </tr>
            </thead>
            <tbody>
              <post
                v-for="(item, index) in posts"
                :key="item.uuid"
                :item="item"
                :index="index"
              />
            </tbody>
          </table>
          <h5 v-else class="title is-5 has-text-centered">Δε βρέθηκαν άρθρα</h5>
        </div>
      </ToggleableCard>

      <StickyFormFooter>
        <template v-slot:left>
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </template>
        <template v-slot:right>
          <a
            v-tooltip="'Προβολή στο e-shop'"
            :href="model.path"
            target="_blank"
          >
            {{ model.name }} <i class="fa fa-arrow-right" />
          </a>
        </template>
      </StickyFormFooter>
    </form>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import LoadingContainer from '@/views/components/LoadingContainer';
import EditItem from '@/views/components/EditItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import EditPhotos from '@/views/components/EditPhotos';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import Post from './components/Post';

export default {
  components: {
    LoadingContainer,
    ToggleableCard,
    EditPhotos,
    StickyFormFooter,
    Post,
  },

  extends: EditItem,

  data() {
    return {
      general: {},
      photos: [],
      isLoadingPhotos: false,
    };
  },

  computed: {
    ...mapGetters({
      model: 'authors/edit/getAuthor',
      posts: 'authors/edit/getPosts',
      isLoadingAuthor: 'authors/edit/getIsLoading',
    }),
  },

  watch: {
    model: {
      handler(newVal) {
        this.general = _pick(newVal, [
          'name',
          'specialty',
          'bio',
          'facebook',
          'twitter',
          'instagram',
          'linkedin',
          'webpage',
        ]);

        this.photos = _clone(this.model.photos);
      },
      deep: true,
    },
  },

  async created() {
    try {
      await this.getAuthor({ uuid: this.$route.params.uuid });
    } catch (err) {
      this.$router.push('/error');
    }
  },

  methods: {
    ...mapActions({
      getAuthor: 'authors/edit/getAuthor',
      updateAuthor: 'authors/edit/updateAuthor',
      getAuthorPhotos: 'authors/edit/getAuthorPhotos',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateAuthor({
          uuid: this.model.uuid,
          author: this.general,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'O author αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.general = _pick(this.model, [
        'name',
        'specialty',
        'bio',
        'facebook',
        'twitter',
        'instagram',
        'linkedin',
        'webpage',
      ]);

      this.photos = _clone(this.model.photos);
    },

    updateMeta(meta) {
      this.meta = meta;
    },

    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },

    updateGeneral(general) {
      this.general = general;
    },

    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getAuthorPhotos({ uuid: this.$route.params.uuid });
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },
  },
};
</script>
