<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Barcodes
        </div>
      </div>
    </div>
    <div class="card-content">
      <template v-if="barcodes.length">
        <barcode-item
          v-for="barcode in barcodes"
          :key="barcode.id"
          :type="type"
          :barcode="barcode"
          :barcodes="barcodes"
          :photos="photos"
          :colors="colors"
          :sizes="sizes"
          @on-delete="handleDelete"
          @on-update="handleUpdate"
          @on-update-barcode-photo="handleUpdatePhoto"
        />
      </template>

      <h5 v-else class="is-5 has-text-centered is-marginless">
        Δε βρέθηκαν barcodes
      </h5>
    </div>
  </div>
</template>

<script>
import BarcodeItem from './components/BarcodeItem';

export default {
  inject: ['$validator'],

  components: {
    BarcodeItem,
  },

  props: {
    type: String,
    barcodes: Array,
    photos: Array,
    colors: Array,
    sizes: Array,
  },

  methods: {
    handleUpdate(barcode) {
      this.$emit(
        'updateBarcodes',
        this.barcodes.map(item => (item.id === barcode.id ? barcode : item)),
      );
    },

    handleDelete(id) {
      this.$emit(
        'updateBarcodes',
        this.barcodes.filter(item => item.id !== id),
      );
    },

    handleUpdatePhoto() {
      this.$emit('on-update-barcode-photo');
    },
  },
};
</script>
