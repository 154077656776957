<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Επιλογή Πελάτη *
        </div>
      </div>
      <div class="card-header-icon">
        <button
          v-tooltip="{ content: 'Προσθήκη Πελάτη' }"
          class="button is-small"
          @click="addCustomer"
        >
          <span class="icon is-small"><i class="fa fa-plus"/></span>
        </button>
      </div>
    </div>
    <div class="card-content">
      <div v-if="order.orderable_id" class="info has-text-centered">
        <h4 class="title is-4 is-spaced">
          {{ order.customer.fullName }}
        </h4>
        <h4 class="subtitle is-6">
          {{ order.customer.email }}
        </h4>
        <hr />
      </div>

      <div class="field">
        <label class="label">Πελάτης</label>
        <div class="control">
          <customers-search
            :customer="order.customer"
            @selectCustomer="selectCustomer"
          />
          <p v-show="errors.has('customer')" class="help is-danger">
            Επιλέξτε πελάτη
          </p>
        </div>
      </div>

      <template v-if="hasCustomer && Object.keys(customerEmails).length">
        <div class="field">
          <label class="label">Επιλογή Email</label>
          <div v-for="(key, index) in Object.keys(customerEmails)" :key="index">
            <template v-if="customerEmails[key].length">
              <div
                v-for="(email, index) in customerEmails[key]"
                :key="`${key}-${index}`"
                class="control"
              >
                <radio
                  :value="email"
                  :checked="emailsCount === 1"
                  name="inform_client"
                  @input="toggleRadio(email)"
                />
                <label>
                  {{ email }}
                  <span
                    v-tooltip="{ content: 'Profile' }"
                    v-if="key === 'profile'"
                    class="tag"
                  >
                    P
                  </span>
                </label>
              </div>
            </template>
          </div>
        </div>

        <div v-if="!!customerEmail && canHaveProfileType" class="field">
          <label class="label">Χρήση στοιχείων Profile</label>
          <div class="control">
            <checkbox v-model="isProfile" name="is_profile" />
            <label>Παραγγελία ως Profile</label>
          </div>
        </div>
      </template>
    </div>

    <div v-if="order.orderable_id" class="card-footer">
      <button type="button" class="button" @click="reset">
        <span class="icon is-small"><i class="fa fa-undo"/></span
        ><span>Επαναφορά</span>
      </button>
    </div>

    <add-customer :is-open="isOpen" @closeModal="closeModal" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { isEmpty } from 'lodash';
import CustomersSearch from './components/CustomersSearch';
import AddCustomer from './components/AddCustomer';

export default {
  inject: ['$validator'],
  components: {
    CustomersSearch,
    AddCustomer,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      customer: 'getCustomer',
      customerEmails: 'getCustomerEmails',
      customerEmail: 'getCustomerEmail',
      hasCustomer: 'getHasCustomer',
      canHaveProfileType: 'getCanHaveProfileType',
    }),

    emailsCount() {
      return Object.keys(this.customerEmails).reduce(
        (count, key) => count + this.customerEmails[key].length,
        0,
      );
    },

    singleEmail() {
      // When a customer has only one email we need to get its value in order to pass it in toggleRadio method
      // through a watcher, as it's pre-checked and the toggleRadio method is never called
      if (this.emailsCount === 1) {
        const emailKey = Object.keys(this.customerEmails).find(
          key => this.customerEmails[key].length,
        );

        return this.customerEmails[emailKey][0];
      }

      return null;
    },

    isProfile: {
      get() {
        return this.$store.getters.getIsProfile;
      },
      set(value) {
        this.updateCustomerType({
          customer_type: value === true ? 'profile' : 'guest',
        });
      },
    },
  },
  watch: {
    singleEmail: {
      handler(newVal) {
        if (!isEmpty(newVal)) {
          this.toggleRadio(newVal);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations([
      'addNewOrderCustomer',
      'resetOrderCustomer',
      'updateCustomerType',
      'updateCustomerEmail',
    ]),
    selectCustomer(customer) {
      this.addNewOrderCustomer(customer);

      this.$validator.reset();
    },
    addCustomer() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    reset() {
      this.resetOrderCustomer();

      this.$validator.reset();
    },
    toggleRadio(email) {
      this.updateCustomerEmail({
        email,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  margin-bottom: 1rem;
}

.is-spaced {
  margin-bottom: 0.5rem;
}

.fa {
  &.fa-user {
    font-size: 40px;
    margin-bottom: 1rem;
  }
}

.checkbox-component,
.radio-component,
.label {
  display: inline-block;
}

.checkbox-component,
.radio-component {
  margin-right: 10px;
  vertical-align: middle;
}
</style>
