<template>
  <LoadingContainer :is-loading="isLoading">
    <form @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Άρθρα <span class="tag">Προσθήκη Άρθρου</span>
            </div>
          </div>
          <div class="card-header-icon">
            <router-link :to="{ name: 'content.posts.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
        <div class="card-content">
          <div class="field">
            <label class="label">Τίτλος *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model.trim="general.title"
                class="input"
                type="text"
                name="title"
              />
              <p v-show="errors.has('title')" class="help is-danger">
                Εισάγετε τίτλο
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Slug *</label>
            <div class="control">
              <input
                v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                v-model.trim="general.slug"
                class="input"
                type="text"
                name="slug"
              />
              <p v-show="errors.has('slug')" class="help is-danger">
                Εισάγετε έγκυρο slug
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Περιγραφή *</label>
            <div class="control">
              <textarea
                v-validate="'required|max:290'"
                v-model.trim="general.description"
                class="textarea"
                name="description"
              />
              <p
                v-for="(error, index) in errors.collect('description')"
                :key="index"
                class="help is-danger"
              >
                Η {{ error.replace('description', 'περιγραφή') }}
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Περιεχόμενο *</label>
            <div class="control">
              <editor
                :content="general.content"
                @changeContent="changeContent"
              />
              <p v-show="errors.has('content')" class="help is-danger">
                Εισάγετε περιεχόμενο
              </p>
            </div>
          </div>

          <label class="label">
            Ημερομηνία Δημοσίευσης (το sorting γίνεται με αυτή)
          </label>
          <div class="field has-addons">
            <div class="control is-expanded">
              <Datepicker
                v-model="general.published_at"
                name="published_at"
                format="dd-MM-yyyy"
                language="el"
                wrapper-class="field"
                input-class="input"
                placeholder="πχ: 02-12-2017"
              />
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard :is-open="true" title="Author">
        <div class="card-content">
          <div class="field">
            <authors-search
              :author="general.author"
              @selectAuthor="selectAuthor"
              @removeAuthor="removeAuthor"
            />
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Κατηγορίες - Συμπτώματα - Tags">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Κατηγορίες *</label>
                <div class="control">
                  <multiselect
                    v-validate="'required'"
                    v-model="general.postCategories"
                    :options="postCategories"
                    :multiple="true"
                    name="postCategories"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε κατηγορίες"
                  />
                  <span
                    v-show="errors.has('postCategories')"
                    class="help is-danger"
                  >
                    Επιλέξτε τουλάχιστον μια κατηγορία
                  </span>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <label class="label">Συμπτώματα</label>
              <symptoms-search
                :symptoms="general.symptoms"
                @selectSymptom="selectSymptom"
                @removeSymptom="removeSymptom"
              />
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                  <multiselect
                    v-model="general.tags"
                    :options="tags"
                    :multiple="true"
                    name="tags"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε tags"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Σχετικά Προϊόντα">
        <div class="card-content">
          <div class="field">
            <label class="label">Σχετικά Προιόντα</label>
            <div class="control">
              <products-search
                :products="general.products"
                @selectProduct="handleSelectProduct"
                @removeProduct="handleRemoveProduct"
              />
            </div>
          </div>
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import slug from 'slug';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import LoadingContainer from '@/views/components/LoadingContainer';
import ToggleableCard from '@/views/components/ToggleableCard';
import AddItem from '@/views/components/AddItem';
import Editor from '@/views/components/Editor';
import SymptomsSearch from '../components/SymptomsSearch';
import AuthorsSearch from '../components/AuthorsSearch';
import ProductsSearch from '../../../Questions/views/components/ProductsSearch';

export default {
  components: {
    Multiselect,
    Editor,
    Datepicker,
    LoadingContainer,
    ToggleableCard,
    SymptomsSearch,
    AuthorsSearch,
    ProductsSearch,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        slug: '',
        description: '',
        content: '',
        author: null,
        author_id: null,
        published_at: moment().toDate(),
        postCategories: [],
        tags: [],
        symptoms: [],
        products: [],
      },
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      tags: 'tags/all/getTags',
      postCategories: 'postCategories/all/getPostCategories',
      isLoading: 'postCategories/list/getIsLoading',
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    'general.title': function(newVal) {
      this.general = {
        ...this.general,
        slug: slug(newVal.toLowerCase()),
      };
    },
  },
  async created() {
    try {
      await Promise.all([this.getTags(), this.getPostCategories()]);
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      addPost: 'posts/add/addPost',
      getTags: 'tags/all/getTags',
      getPostCategories: 'postCategories/all/getPostCategories',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addPost({
          ...this.general,
          products: this.general.products.map(({ id }) => id),
          published_at: moment(this.general.published_at).format('DD-MM-YYYY'),
          postCategories: this.general.postCategories.map(({ id }) => id),
          tags: this.general.tags.map(({ id }) => id),
          symptoms: this.general.symptoms.map(({ id }) => id),
        });
        this.isSaving = false;

        this.$router.push({ name: 'content.posts.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το άρθρο αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        title: '',
        slug: '',
        description: '',
        content: '',
        author: null,
        author_id: null,
        published_at: moment().toDate(),
        postCategories: [],
        tags: [],
        symptoms: [],
        products: [],
      };

      this.$validator.reset();
    },
    changeContent(content) {
      this.general.content = content;
    },
    selectSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: [...this.general.symptoms, symptom],
      };
    },
    removeSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: this.general.symptoms.filter(({ id }) => id !== symptom.id),
      };
    },
    selectAuthor(author) {
      this.general = {
        ...this.general,
        author,
        author_id: author.id,
      };
    },
    removeAuthor() {
      this.general = {
        ...this.general,
        author: null,
        author_id: null,
      };
    },
    handleSelectProduct(product) {
      this.general = {
        ...this.general,
        products: [...(this.general.products || []), product],
      };
    },

    handleRemoveProduct(product) {
      this.general = {
        ...this.general,
        products: this.general.products.filter(({ id }) => id !== product.id),
      };
    },
  },
};
</script>
