<template>
  <div>
    <div class="field">
      <label class="label">{{ title }} *</label>
    </div>
    <div class="card-content field">
      <div class="card-header-icon">
        <button type="button" class="button" @click="openUploadModal()">
          <div v-if="canUploadImage">
            <span class="icon is-small"><i class="fa fa-plus"/></span>
            <span>
              Προσθήκη
            </span>
          </div>

          <div v-else>
            <span class="icon is-small"><i class="fa fa-repeat"/></span>
            <span>
              Αντικατάσταση
            </span>
          </div>
        </button>
      </div>
      <template v-if="preview">
        <div class="column ">
          <image-card
            :preview="preview"
            :can-delete-avatar="canDeleteAvatar"
            @selectPhotoToDelete="selectPhotoToDelete"
          />
        </div>
      </template>

      <image-upload-modal
        :is-open="isOpenUploadModal"
        :is-saving="isSaving"
        @closeUploadModal="closeUploadModal"
        @upload="add"
      />

      <confirmation
        v-if="selectedPhoto"
        :is-open="isOpenDeleteModal"
        :is-saving="isSaving"
        @closeModal="closeDeleteModal"
      >
        <h5 class="title is-5">
          Είστε βέβαιος για τη διαγραφή αυτής της φωτογραφίας;
        </h5>
      </confirmation>
    </div>

    <p v-show="showError" class="help is-danger">
      Επιλέξτε banner
    </p>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import Draggable from 'vuedraggable';
import LoadingContainer from '@/views/components/LoadingContainer';
import ImageUploadModal from '@/views/components/ImageUploadModal';
import ImagePreviewModal from '@/views/components/ImagePreviewModal';
import Confirmation from '@/views/components/Confirmation';
import Notification from '@/views/components/Notification';
import ImageCard from './components/ImageCard';

export default {
  components: {
    Draggable,
    LoadingContainer,
    ImageUploadModal,
    ImagePreviewModal,
    Confirmation,
    Notification,
    ImageCard,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    preview: {
      type: String,
      required: true,
    },
    canDeleteAvatar: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    newPhoto: null,
    isSaving: false,
    selectedPhoto: null,
    isOpenUploadModal: false,
    isOpenPreviewModal: false,
    isOpenDeleteModal: false,
  }),

  computed: {
    canUploadImage() {
      return isEmpty(this.preview);
    },
  },

  methods: {
    add(file) {
      this.newPhoto = file;

      this.$emit('addPhotos', file);
      this.isOpenUploadModal = false;
    },

    openUploadModal() {
      this.isOpenUploadModal = true;
    },

    closeUploadModal() {
      this.isOpenUploadModal = false;
    },

    async closeDeleteModal(response) {
      if (response) this.newPhoto = null;

      this.isOpenDeleteModal = false;
      this.selectedPhoto = null;
    },

    selectPhotoToDelete(photo) {
      this.selectedPhoto = photo;

      this.isOpenDeleteModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.card-content {
  border: 1px solid #dbdbdb;

  .columns {
    margin-bottom: 0;
  }
}

.help,
.card-content {
  margin-bottom: 0.75rem;
}
</style>
